@use "sass:map";

/* #region app colors */

$ing-primary-palette: (
  50: #F0F0F0,
  100: #ffcfb1,
  200: #ffb180,
  300: #ff914d,
  400: #ff7a26,
  500: #ff6200,
  600: #ff5a00,
  700: #ff5000,
  800: #ff4600,
  900: #ff3400,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
  )
);

$ing-secondary-palette: (
  50: #e0e0ed,
  100: #b3b3d1,
  200: #8080b3,
  300: #4d4d94,
  400: #26267d,
  500: #000066,
  600: #00005e,
  700: #000053,
  800: #000049,
  900: #000038,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
  )
);

$ing-err-palette: (
  100: #f59494,
  500: #D70000,
  700: #d63030,
  900: #D70000,
  contrast: (
    100: #ffffff,
    500: #ffffff,
    700: #ffffff,
    900: #ffffff,
  )
);

$ing-shades: (
  0: white,
  50: #F0F0F0,
  100: #D9D9D9,
  500: #B0B0B0,
  600: #646464,
  900: black,
  contrast: (
    0: black,
    50: black,
    100: black,
    500: black,
    600: white,
    900: white,
  )
);

$ing-primary-color: map.get($ing-primary-palette, 500);
$ing-primary-color-contrast: map.get($ing-primary-palette, "contrast", 500);
$ing-primary-shade-color: map.get($ing-primary-palette, 100);
$ing-secondary-color: map.get($ing-secondary-palette, 500);

/* #endregion */

/* #region app dark colors */
$ing-dark-primary-palette: (
  900: #F0F0F0,
  800: #ffcfb1,
  700: #ffb180,
  600: #ff914d,
  500: #ff7a26,
  400: #ff6200,
  300: #ff5a00,
  200: #ff5000,
  100: #ff4600,
  50: #ff3400,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
  )
);

$ing-dark-secondary-palette: (
  900: #e0e0ed,
  800: #b3b3d1,
  700: #8080b3,
  600: #4d4d94,
  500: #26267d,
  400: #000066,
  300: #00005e,
  200: #000053,
  100: #000049,
  50: #000038,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
 )
);

$ing-dark-err-palette: (
  100: #f59494,
  500: #D70000,
  700: #d63030,
  900: #D70000,
  contrast: (
    100: #000000,
    500: #000000,
    700: #000000,
    900: #000000,
  )
);

$ing-dark-shades: (
  900: white,
  600: #F0F0F0,
  500: #D9D9D9,
  100: #B0B0B0,
  50: #646464,
  0: black,
  contrast: (
    0: white,
    50: white,
    100: white,
    500: white,
    600: black,
    900: black,
  )
);

/* #endregion */

/* #region other colors */

/* request status colors */
$ing-req-status-c-IPR: #25b951;
$ing-req-status-c-CAN: #ff0202;
$ing-req-status-c-CLS: #838383;


/* project status colors */
$ing-proj-status-c-OPN: #25b951;
$ing-proj-status-c-CLS: #838383;

/* shared colors */

/* #endregion */

/* #region fonts */
@font-face {
  font-family: 'ING Me';
  src: url(./assets/fonts/INGMeWeb-Regular.woff) format('woff2'), url(./assets/fonts/INGMeWeb-Regular.woff) format('woff');
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.5px;
}

@font-face {
  font-family: 'ING Me';
  src: url(./assets/fonts/INGMeWeb-Bold.woff2) format('woff2'), url(./assets/fonts/INGMeWeb-Bold.woff) format('woff');
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.1px;
}

@font-face {
  font-family: 'ING Me';
  src: url(./assets/fonts/INGMeWeb-Italic.woff2) format('woff2'), url(./assets/fonts/INGMeWeb-Italic.woff) format('woff');
  font-style: italic;
  font-weight: normal;
  letter-spacing: 0.5px;
}

/* #endregion */

$ing-panel-shadow: 0px 0px 5px 0px #00000008, 0px 4px 4px 0px #0000000d;

body {
  /* primary palette */
  --primary-palette-50: #F0F0F0;
  --primary-palette-100: #ffcfb1;
  --primary-palette-200: #ffb180;
  --primary-palette-300: #ff914d;
  --primary-palette-400: #ff7a26;
  --primary-palette-500: #ff6200;
  --primary-palette-600: #ff5a00;
  --primary-palette-700: #ff5000;
  --primary-palette-800: #ff4600;
  --primary-palette-900: #ff3400;
  /* primary palette contrast */
  --primary-palette-c-50: #000000;
  --primary-palette-c-100: #000000;
  --primary-palette-c-200: #000000;
  --primary-palette-c-300: #000000;
  --primary-palette-c-400: #000000;
  --primary-palette-c-500: #ffffff;
  --primary-palette-c-600: #ffffff;
  --primary-palette-c-700: #ffffff;
  --primary-palette-c-800: #ffffff;
  --primary-palette-c-900: #ffffff;

  /* secondary palette */
  --secondary-palette-50: #e0e0ed;
  --secondary-palette-100: #b3b3d1;
  --secondary-palette-200: #8080b3;
  --secondary-palette-300: #4d4d94;
  --secondary-palette-400: #26267d;
  --secondary-palette-500: #000066;
  --secondary-palette-600: #00005e;
  --secondary-palette-700: #000053;
  --secondary-palette-800: #000049;
  --secondary-palette-900: #000038;
  /* secondary palette contrast */
  --secondary-palette-c-50: #000000,
  --secondary-palette-c-100: #000000,
  --secondary-palette-c-200: #000000,
  --secondary-palette-c-300: #ffffff,
  --secondary-palette-c-400: #ffffff,
  --secondary-palette-c-500: #ffffff,
  --secondary-palette-c-600: #ffffff,
  --secondary-palette-c-700: #ffffff,
  --secondary-palette-c-800: #ffffff,
  --secondary-palette-c-900: #ffffff,

  /* error palette */
  --err-palette-100: #f59494;
  --err-palette-500: #D70000;
  --err-palette-700: #d63030;
  --err-palette-900: #D70000;
  /* error palette contrast */
  --err-palette-c-100: #ffffff;
  --err-palette-c-500: #ffffff;
  --err-palette-c-700: #ffffff;
  --err-palette-c-900: #ffffff;

  /* shades */
  --shades-0: white;
  --shades-50: #F0F0F0;
  --shades-100: #D9D9D9;
  --shades-500: #B0B0B0;
  --shades-600: #646464;
  --shades-900: black;
  /* shades contrast */
  --shades-c-0: black;
  --shades-c-50: black;
  --shades-c-100: black;
  --shades-c-500: black;
  --shades-c-600: white;
  --shades-c-900: white;

  /* primary color */
  --primary-color: #ff6200;
  --primary-color-contrast: #ffffff;
  --primary-shade-color: #D9D9D9;
  --secondary-color: #000066;

}