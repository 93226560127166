@use '@angular/material' as mat;
@use "sass:map";

@include mat.core();
@import "./theme.scss";

/* #region */
/* #endregion */

/* #region material theme */

$FrontEnd-primary: mat.define-palette($ing-primary-palette);
$FrontEnd-accent: mat.define-palette($ing-secondary-palette);
$FrontEnd-warn: mat.define-palette($ing-err-palette);

$FrontEnd-primary-dark: mat.define-palette($ing-dark-primary-palette);
$FrontEnd-accent-dark: mat.define-palette($ing-dark-secondary-palette);
$FrontEnd-warn-dark: mat.define-palette($ing-dark-err-palette);

$ing-typography: mat.define-typography-config(
  $font-family: 'ING Me',
);

$FrontEnd-theme: mat.define-light-theme((
  color: (
    primary: $FrontEnd-primary,
    accent: $FrontEnd-accent,
    warn: $FrontEnd-warn,
  ),
  typography: $ing-typography,
  density: -2.5,
));

$FrontEnd-dark-theme: mat.define-dark-theme((
  color: (
    primary: $FrontEnd-primary-dark,
    accent: $FrontEnd-accent-dark,
    warn: $FrontEnd-warn-dark,
  ),
  typography: $ing-typography,
  density: -2.5,
));

@include mat.all-component-themes($FrontEnd-theme);

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-label-text-color: #ffffff;
}

/* #endregion */


html, body { 
  height: 100vh;
  width: 100vw;
}
body { 
  margin: 0; 
  padding: 0;
  overflow: hidden;
  background-color: white;
  font-family: 'ING Me', sans-serif;

  --ing-primary-color: #{ $ing-primary-color };
  --ing-warn-color: #{ map.get($ing-err-palette, 700) };
  --ing-panel-shadow: #{ $ing-panel-shadow };
  --mdc-checkbox-state-layer-size: 40px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}


/* #region buttons */
.mdc-button {
  --mdc-typography-button-font-size: 15px;
  --mdc-typography-button-letter-spacing: 0.5px;

  min-width: 100px !important;

}
span.mdc-button__label {
  display: flex;
  gap: 5px;
  
  img.button-icon {
    width: 21px;
    height: 21px;  
  }
}
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-label-text-color: #{ map.get($ing-primary-palette, "contrast", 500) };
}
.mat-mdc-button.mat-mdc-button-base, .mat-mdc-raised-button.mat-mdc-button-base, .mat-mdc-unelevated-button.mat-mdc-button-base, .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 31px;
}
.mat-mdc-button.mat-accent, .mat-mdc-raised-button.mat-accent, .mat-mdc-unelevated-button.mat-accent, .mat-mdc-outlined-button.mat-accent {
  --mdc-typography-button-font-weight: 700;
  --mdc-typography-button-letter-spacing: 0.1px;
  --mdc-protected-button-container-color: #{ map.get($ing-primary-palette, 100) };
  --mdc-filled-button-container-color: #{ map.get($ing-primary-palette, 100) };
  --mdc-protected-button-label-text-color: #{ map.get($ing-primary-palette, 900) };
  --mdc-filled-button-label-text-color: #{ map.get($ing-primary-palette, 900) };
  --mat-mdc-button-persistent-ripple-color: #{ map.get($ing-primary-palette, "contrast", 100) };
}

.mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #{ map.get($ing-primary-palette, "contrast", 500) };
}

.mat-mdc-fab, .mat-mdc-mini-fab {
  box-shadow: none !important;
}
.mat-mdc-fab .mdc-button__label, .mat-mdc-mini-fab .mdc-button__label {
  display: flex;
  align-items: center;
}

a {
  text-decoration: none;
  color: $ing-primary-color;
  cursor: pointer;

  &:not(.link) {
    text-decoration: underline;
  }
}
a:hover {
  transform: translateY(-2px);
}
/* #endregion */

/* #region scrollbars */
* {
  scrollbar-width: auto;
  scrollbar-color: $ing-primary-shade-color transparent;
}

*::-webkit-scrollbar {
  width: 14px;
  height: 14px;
  cursor: pointer !important;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: $ing-primary-shade-color;
  border-radius: 14px;
  border: 3px solid white;
}
/* #endregion */

.panel-shadow, .panel {
  box-shadow: $ing-panel-shadow;
}
$panel-border-radius: 10px;
.panel {
  display: flex;
  border-radius: $panel-border-radius;
  flex-direction: column;
  gap: 15px;
  padding: 20px 15px;
  background-color: map.get($ing-shades, 0);
  border: 1px solid map.get($ing-shades, 100);
}
.panel, .table-container {
  h3.panel-title {
    flex: 0 0 auto;
    padding: 0;
    margin: 0;
    padding-left: 5px;
    font-size: 20px;
    font-weight: normal;
    color: map.get($ing-shades, 900);
  }
}
.table-container {
  h3.panel-title {
    margin-top: 20px;
    margin-left: 15px;
  }
}
.step-description {
  margin-bottom: 20px;
  color: map.get($ing-shades, 600);
  font-size: 16px;
}

form mat-form-field, form .mat-form-field, .mat-form-field.force-styling {
  min-height: 70px;
  grid-column: span 2;

  &.mat-form-field-disabled {
    cursor: not-allowed;

    * {
      cursor: not-allowed;
    }
  }

  &:not(.non-view-field) {
    --mdc-outlined-text-field-disabled-label-text-color: #{map.get($ing-shades, 900)};
    --mdc-outlined-text-field-disabled-input-text-color: #{map.get($ing-shades, 900)};
    --mdc-outlined-text-field-disabled-outline-color: #{map.get($ing-shades, 900)};
  }

  mat-label.mat-button-toggle-label {
    height: 18.5px;
    margin-top: -10px;
    padding-left: 14px;
    display: block;
    box-sizing: border-box;
    font-size: 13px;
    color: var(--mdc-outlined-text-field-label-text-color);
  }
}

form {

  display: grid;
  grid-template-columns: repeat(8, 1fr);
  row-gap: 5px;
  column-gap: 20px;

  .fake-field {
    background-color: map.get($ing-shades, 50);
    height: 48.4px;
    border-radius: 4px;
  }

  h3 {
    grid-column: 1 / -1;
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 5px;

    &:not(.no-line)::after {
      content: "";
      display: block;
      flex: 1 1 0;
      height: 1px;
      background-color: map.get($ing-shades, 500);
    }
  }

  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  .full-width {
    grid-column: 1 / -1;
  }
 
  .span-1 {
    grid-column: span 1;
  }
 
  .span-2 {
    grid-column: span 2;
  }

  .span-4 {
    grid-column: span 4;
  }
 
  .span-6 {
    grid-column: span 6;
  }

  mat-button-toggle-group {
    width: 100%;
    
    --mat-standard-button-toggle-divider-color: var(--mdc-outlined-text-field-outline-color);
    --mat-standard-button-toggle-selected-state-background-color: #{map.get($ing-primary-palette, 100)};
    
    mat-button-toggle {
      width: 100%;
      
      span.mat-button-toggle-label-content {
        line-height: 37px !important;
      }
    }
  }
}

.error-label {
  color: map.get($ing-err-palette, 900);
  margin: 5px 0 10px;
  display: flex;

  &:not(.no-icon)::before {
    content: "";
    display: inline-block;
    width: 20px; 
    height: 20px;
    margin-right: 5px;
    background-size: contain;
    background-image: url(/assets/icons/err-filled-red.svg);
  }

  &:not(.not-animated) {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
}

form > .actions-container, .panel-actions-container {
  grid-column: 1 / -1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  height: 25.6px;

  &.m-top {
    margin-top: 5px;
  }
  &.m-bottom {
    margin-bottom: 20px;
  }

  > h3.panel-title {
    flex: 1 1 0;
  }

  > * {
    flex: 0 0 auto;
  }
}

.mat-datepicker-content {
  margin-top: 5px;
  border-radius: 15px !important;
}

@media (max-width: 1200px) {
  form {
    grid-template-columns: repeat(4, 1fr);

    .span-6 {
      grid-column: 1 / -1;
    }
  }
}
@media (max-width: 800px) {
  app-sidemenu {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99;
    pointer-events: none;
    
    > * {
      pointer-events: all;
    }
    
    > .sidemenu-panel {
      box-shadow: 20px 0px 300px #6c6c6c;
    }
  }
}
@media (max-width: 500px) {
  form {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  
    mat-form-field {
      grid-column: span 1;  
    }

    .full-width {
      grid-column: 1 / -1;
    }
   
    .span-1, .span-2, .span-4, .span-6 {
      grid-column: span 1;
    }
  }
}

.mdc-text-field--outlined {
  background-color: white;
}

.white-icon {
  filter: brightness(0) invert(1);
}

.black-icon {
  filter: brightness(0) invert(0);
}

mat-button-toggle-group, form mat-button-toggle-group {
  --mat-standard-button-toggle-selected-state-background-color: #{map.get($ing-shades, 0)};
}
.mat-button-toggle-checked {
  > button {
    border: 1px solid;
    border-color: $ing-primary-color; 
    color: $ing-primary-color;
    font-weight: bold;
  }

  --mat-standard-inner-button-toggle-shape: calc(var(--mat-standard-button-toggle-shape) - 1px);

  &:first-child > button {
    border-top-left-radius: var(--mat-standard-inner-button-toggle-shape);
    border-bottom-left-radius: var(--mat-standard-inner-button-toggle-shape);
  }
  &:last-child > button {
    border-top-right-radius: var(--mat-standard-inner-button-toggle-shape);
    border-bottom-right-radius: var(--mat-standard-inner-button-toggle-shape);
  }
}


/* #region table */
.table-container {
  border: 1px solid map.get($ing-shades, 100);
  background-color: map.get($ing-shades, 100);
  box-shadow: $ing-panel-shadow;
  position: relative;
  border-radius: 10px;

  > .loading {
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    
    &.is-loading {
      pointer-events: all;
      cursor: wait;
      opacity: 1;
    }
  }

  > .inner-table-container {
    $border-radius: $panel-border-radius;
    background-color: white;
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
    overflow-x: auto;
    overflow-y: hidden;
    min-height: 250px;

    &:last-child {
      border-bottom-right-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
  }

  table[mat-table], table.mat-table {
    background-color: transparent;
    
    thead, tbody {
      
      tr:first-of-type {
        th:first-child {
          border-top-left-radius: 9px;
        }
      }

      th.sticky-col, td.sticky-col {
        box-shadow: -1px 0 0px 0px map.get($ing-shades, 500), 1px 0 0px 0px map.get($ing-shades, 500);
        position: sticky;
        left: 0;
        right: 0;
      }
      th.sticky-col {
        background-color: map.get($ing-shades, 100);
      }
      td.sticky-col {
        background-color: map.get($ing-shades, 50);
      }

      .mat-mdc-no-data-row {
        text-align: center;
        height: 200px;
        position: relative;

        td {
          display: flex;
          font-size: 20px;
          position: sticky;
          width: 0px;
          overflow: visible;
          white-space: nowrap;
          margin-top: 20px;
          left: 50%;
          right: 50%;
          justify-content: center;
        }
      }
    } 
  }

  > mat-paginator {
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    overflow: hidden;
  }  
}

*[mat-header-row], *.mat-header-row {
  height: 30px !important;
  background-color: map.get($ing-shades, 100) !important;
  
  &:not(:last-child) {
    *[mat-header-cell], *.mat-header-cell {
      border-bottom-width: 0px;
      font-size: 14px;

      &::after {
        bottom: -3px;
      }
    }
  }

  &:not(:first-child) {
    *[mat-header-cell], *.mat-header-cell {
      &::after {
        top: -3px;
      }
    }
  }

}
*[mat-header-cell], *.mat-header-cell {
  color: map.get($ing-shades, 900);
  font-size: 12px;
  position: relative;

  &.text-center {
    text-align: center;
  }
  

  &:not(:last-child)::after {
    content: "";
    position: absolute;
    width: 1px;
    right: 0;
    top: 3px;
    bottom: 3px;
    display: block;
    background-color: map.get($ing-shades, 500);
  }
}

*[mat-row], *.mat-row {
  position: relative;

  &:not(.mat-mdc-no-data-row):not(.mat-mdc-no-hover-row):hover {
    background-color: map.get($ing-primary-palette, 100) !important;
    background-color: map.get($ing-shades, 50) !important;
  }

  &:not(:last-child)::after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    border-bottom-color: map.get($ing-shades, 500);
    border-bottom-width: 1px;
    border-bottom-style: dashed;
    background-size: 20px 2px;
  }
}
*[mat-cell], *.mat-cell {
  //background-color: map.get($ing-shades, 0);
  color: map.get($ing-shades, 900);
  font-size: 15px;
  border: 0px !important;

  &.text-center {
    text-align: center;
  }
}
.mat-mdc-paginator {
  padding: 5px 0 10px;
}
.mat-column-actions {
  .mat-tooltip-panel {
    pointer-events: none;
  }
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 2px;
  padding-bottom: 2px;
}
.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 30px;
}

/* #endregion */

[hideXAxisTicks] {
  [ngx-charts-x-axis-ticks] {
    > g {
      ~ g {
        display: none;
      }
    }
  }
}
[hideYAxisTicks] {
  [ngx-charts-y-axis-ticks] {
    > g {
      ~ g {
        display: none;
      }
    }
  }
}
[ngx-charts-bar-label] text {
  transform: translateY(-5px) !important;
  text-anchor: middle !important;
}

.select-search {
  position: sticky;
  top: -8px;
  margin-top: -8px;
  background-color: white;
  padding: 5px;
  z-index: 1;

  > mat-form-field {
    width: 100%;
    margin-top: 8px;

    .mat-mdc-form-field-bottom-align {
      display: none;
    }
  }
}
mat-option[data-hidden="true"] {
  display: none !important;
}

.mat-datepicker-content .mat-calendar {
  width: 300px !important;
}

//#region table summary
.table-summary {
  display: flex;
  justify-content: flex-start;
  padding: 10px 15px 5px;
  align-items: center;
  gap: 5px;

  > .divider {
    flex: 1 1 0;
  }
}

.summary {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: auto;
  
  .summary-group {
    background-color: map.get($ing-shades, 50);
    border-radius: 5px;
    margin: 5px -5px;
    padding: 5px;

    > h3 {
      margin: 0 0 10px;
      padding: 0;
      font-size: 16px;
      font-weight: normal;
      color: map.get($ing-shades, 900);
    }
  }

  .summary-item {
    display: grid;
    grid-template-columns: min(150px, 50%) auto;
    align-items: center;
    min-height: 35px;
    flex-wrap: wrap;
    width: 100%;
    background-size: 20px 2px;
    gap: 15px;
  
    &.column {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
      gap: 5px;
  
      > *:not(.value) {
        margin-top: 9px;
      }
    }
  
    &:not(:last-child) {
      border-bottom: 1px dashed map.get($ing-shades, 500);
    }
  
    > * {
      grid-column: span 1;
      font-size: 12px;
      font-weight: bold;
      
      &.summary-item-value {
        font-weight: normal;
        font-size: 15px;
      }
  
    } 
    
    &.half {
      flex: 1 1 50%;
    }
  
    &.full {
      flex: 1 1 100%;
    }
  }
}
//#endregion


//#region loading

.skeleton-loading .is-loading {
  --loading-grey: #ededed;

  cursor: wait;
  background-color: var(--loading-grey);
  background: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, .5) 50%,
    rgba(255, 255, 255, 0) 60%
  ) var(--loading-grey);
  background-size: 200% 100%;
  background-position-x: 180%;
  border-radius: 5px;
  animation: 1s skeleton-loading-animation ease-in-out infinite;
}

@keyframes skeleton-loading-animation {
  to {
    background-position-x: -20%;
  }
}

//#endregion